<!-- Version: 1.0 -->
<template>
  <div>
    <div v-if="type != $enums.InputType.file">
      <!-- BaseInput Version: 1.0 -->
      <base-input
        :label="label"
        :placeholder="placeholder"
        :description="description"
        :type="type"
        :required="required"
        :disabled="disabled"
        :error="error"
        :textStyle="textStyle"
        :isInvalidInput="isInvalidInput"
        :state="state"
        :list="list"
        :min="min"
        :max="max"
        v-model="tempValue"
      />
    </div>
    <div v-else>
      <!-- FileInput Version: 1.0 -->
      <file-input
        :label="label"
        :disabled="disabled"
        :required="required"
        :placeholder="placeholder"
        :description="description"
        :error="error"
        :textStyle="textStyle"
        :state="state"
        :multiple="multiple"
        @change="$emit('change', $event)"
      />
    </div>
  </div>
</template>
<script>
import BaseInput from "./DeepComponents/BaseInput.vue";
import FileInput from "./DeepComponents/FileInput.vue";
//import moment from "moment";
export default {
  components: { FileInput, BaseInput },
  name: "fp-input",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    label: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    description: String,
    pattern: String,
    min: Number,
    max: Number,
    error: String,
    textStyle: String, //$enums.InputTextStyle
    isInvalidInput: Boolean,
    type: String, //$enums.InputType
    value: [String, Number, FileList],
    state: { type: Boolean, default: true },
    list: Array,
    multiple: Boolean,
  },
  data() {
    return {
      tempValue: this.value,
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
    value(input) {
      if (this.type != this.$enums.InputType.file) {
        this.tempValue = input;
      }
    },
  },
};
</script>
